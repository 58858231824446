klarna-express-button.klarna-express-btn-cart,
.klarna-express-checkout-cart {
  width: 100% !important;
}

.klarna-express-checkout-pdp {
  width: 100% !important;
}

.keb-hide {
  display: none;
}

.kosm-pdp {
  min-height: 60px;
}

.kosm-cart {
  min-height: 42px;
}

.klarna-express-checkout-cart {
  min-height: 50px;
}